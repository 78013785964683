<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="isFullLoaded">
    <ConfirmModal2
      ref="confirmModal"
      variant="danger"
      :title="confirmModal.title"
      :description="confirmModal.description"
    />
    <ConfirmModal
      v-model="isConfirmModalActive"
      :items="addItem"
      title="Confirm to Add Ticket"
      @confirm="confirmAdd()" />
    <ReOpenConfirmModal
      ref="reOpenConfirmModal"
      :remark.sync="remark"
      @update-remark="remark = $event"
    />
    <ReferModal
      ref="referModal"
      :ticket-code-refer.sync="ticketCodeRefer"
      @update-ticket-code-refer="ticketCodeRefer = $event"
    />
    <TransferModal
      ref="transferModal"
    />
    <StatusModal
      v-model="isSubmitModalActive"
      status-title="Confirm to Add Ticket"
      status-color="#28C76F"
      status-icon="CheckCircleIcon"
      @discard="onDiscard()" />
    <KnowledgeModal
      v-if="isKnowledgeModal"
      ref="knowledgeModal"
      v-model="isKnowledgeModal"
      :is-form-disabled="isFormDisabled || isFormStatus"
      :knowledge-items="knowledgeItems"
      :pagination="knowledgePagination"
      :search.sync="searchKnowledge"
      :ticket="ticket"
      :lvl-options="lvlOptions"
      :categories="allRef.categories"
      @fetch-search="fetchSearchKnowledge()"
      @selectd-row="selectdKnowledge($event)"
      @show-current-page="showCurrentPageKnowledge($event)"
    />
    <SendToEmailModal
      v-if="isSendEmailModal"
      v-model="isSendEmailModal"
      :init-subject.sync="subjectModifier"
      :init-message.sync="messageModifier"
      @click="sendEmail($event)"
      @send-email="sendEmail($event)"
    />
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="TicketAddForm">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <!-- <b-row v-if="ticket && ticket.isNoneVoice"> -->
            <b-row>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.transactionId"
                  :rules="{ required: false }"
                  :disabled="isFormDisabled || isFormStatus"
                  name="transactionId"
                  label="Transaction ID (Non-Voice)"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.contactId"
                  :rules="{ required: false }"
                  :disabled="isFormDisabled || isFormStatus"
                  name="Ticket ID"
                  label="Contact ID (Non-Voice)"
                  trim />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="ticket.code"
                  :rules="{ required: false }"
                  name="Ticket ID"
                  :label="`Ticket ID ${ticket.ticketCodeRefer ? `(Refer: ${ticket.ticketCodeRefer})` : ''}` "
                  disabled
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="ticket.createdAt | formatDateTimeEn"
                  :rules="{ required: false }"
                  name="Open Due"
                  label="Open Due"
                  disabled
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <AutoCompleteField
                  v-model="userName"
                  disabled
                  name="Progress By"
                  label="Progress By"
                  placeholder="Search User By Name"
                  item-value="fullName"
                  :filter-items="filterUser"
                  :items="userItems"
                  @keydown="searchUserByName()"
                  @select="selectUser($event)"
                >
                  <template #item-text="{ item }">
                    {{ item.fullName }}
                  </template>
                </AutoCompleteField>
              </b-col>
              <!-- <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  :value="ticket.ticketStatus | formatTicketStatus"
                  :rules="{ required: false }"
                  name="Ticket Status"
                  label="Ticket Status"
                  disabled
                  trim />
              </b-col> -->
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.ticketTypeId"
                  :rules="{ required: true }"
                  name="Ticket Type"
                  :disabled="isFormDisabled || isFormStatus"
                  form-label="Ticket Type"
                  placeholder="Choose incidentType"
                  label-option="name"
                  return-value="id"
                  :options="allRef.ticketType"
                  @input="chooseTicketType($event)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.customerName"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false }"
                  name="Customer Name"
                  label="Customer Name"
                  placeholder="Input Customer Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.contactName"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false }"
                  name="Contact Name"
                  label="Contact Name"
                  placeholder="Input Contact Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.contactPhoneNo"
                  :is-can-call="true"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false }"
                  name="Contact Phone Number"
                  label="Contact Phone Number"
                  placeholder="Input Contact Phone Number"
                  trim
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.contactChannelId"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false }"
                  name="Contact Channel"
                  form-label="Contact Channel"
                  placeholder="Choose Contact Channel"
                  label-option="name"
                  return-value="id"
                  :options="allRef.contactChannel"
                />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv1"
                  :disabled="!lvlOptions.lvlOneOptions || lvlOptions.lvlOneOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 1"
                  form-label="Category level 1"
                  placeholder="Category level 1"
                  return-value="id"
                  label-option="name"
                  :rules="{ required: isRequiredCategory }"
                  :options="lvlOptions.lvlOneOptions"
                  @input="selectedItem($event, 1)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv2"
                  :disabled="!lvlOptions.lvlTwoOptions || lvlOptions.lvlTwoOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 2"
                  form-label="Category level 2"
                  placeholder="Category level 2"
                  :options="lvlOptions.lvlTwoOptions"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlTwoOptions) }"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 2)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv3"
                  :disabled="!lvlOptions.lvlThreeOptions || lvlOptions.lvlThreeOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 3"
                  form-label="Category level 3"
                  placeholder="Category level 3"
                  :options="lvlOptions.lvlThreeOptions"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlThreeOptions) }"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 3)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv4"
                  :disabled="!lvlOptions.lvlFourOptions || lvlOptions.lvlFourOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 4"
                  form-label="Category level 4"
                  placeholder="Category level 4"
                  :options="lvlOptions.lvlFourOptions"
                  return-value="id"
                  label-option="name"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlFourOptions) }"
                  @input="selectedItem($event, 4)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv5"
                  :disabled="!lvlOptions.lvlFiveOptions || lvlOptions.lvlFiveOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 5"
                  form-label="Category level 5"
                  placeholder="Category level 5"
                  :options="lvlOptions.lvlFiveOptions"
                  return-value="id"
                  label-option="name"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlFiveOptions) }"
                  @input="selectedItem($event, 5)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.categoryLv6"
                  :disabled="!lvlOptions.lvlSixOptions || lvlOptions.lvlSixOptions.length <= 0 || isFormDisabled || isFormStatus"
                  name="Category level 6"
                  form-label="Category level 6"
                  placeholder="Category level 6"
                  :options="lvlOptions.lvlSixOptions"
                  return-value="id"
                  label-option="name"
                  :rules="{ required: checkRequireCategory(lvlOptions.lvlSixOptions) }"
                  @input="selectedItem($event, 6)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.priority"
                  :rules="{ required: false }"
                  disabled
                  name="Priority"
                  form-label="Priority"
                  placeholder="Choose Priority"
                  label-option="label"
                  return-value="value"
                  :options="priorityOption" />
              </b-col>
              <!-- <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="resolveDate"
                  disabled
                  name="Resolve Date"
                  label="Resolve Date"
                  placeholder="Resolve Date"
                />
              </b-col> -->
              <!-- <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="dueDate"
                  disabled
                  name="Due Date"
                  label="Due Date"
                  placeholder="Due Date"
                />
              </b-col> -->
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="ticket.plantId"
                  :rules="{ required: false }"
                  name="Plant"
                  :disabled="isFormDisabled || isFormStatus"
                  form-label="Plant"
                  placeholder="Input Plant"
                  label-option="code"
                  return-value="id"
                  :options="allRef.plant" />
              </b-col>
              <b-col
                sm="4"
                md="4">
                <TextFieldInput
                  v-model="ticket.ticketCause"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false }"
                  name="Incident Cause"
                  label="Incident Cause"
                  placeholder="Input Incident Cause"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.qty"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false, numeric: true }"
                  name="Qty"
                  label="Qty (ครั้ง)"
                  placeholder="Input Qty"
                  trim
                  @keypress="$keypressNumberNoDecimal()"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <!-- <TextFieldInput
                  v-model="ticket.time"
                  v-mask="'##:##'"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false}"
                  name="Time"
                  label="Time (hh:mm)"
                  placeholder="hh:mm"
                  trim
                  @keypress="$keypressNumber()"
                /> -->
                <TimePicker
                  v-model="ticket.time"
                  :disabled="isFormDisabled || isFormStatus"
                  label="Time (hh:mm)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <b-form-group>
                  <b-button
                    :disabled="isFormDisabled || isFormStatus"
                    variant="success"
                    @click="showModal()">
                    Choose Knowledge
                  </b-button>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.subject"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false }"
                  name="Subject"
                  label="Subject"
                  placeholder="Input Subject"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="ticket.remark2"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false, max: 100 }"
                  name="์note"
                  label="Note"
                  placeholder="Input Note"
                  trim />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Incident Detail"
                >
                  <div
                    v-if="!isFormDisabled && !isFormStatus"
                    class="my-2">
                    <PreviewSwitch
                      v-model="isDetailPreview"
                    >
                      Preview
                    </PreviewSwitch>
                  </div>
                  <div
                    v-if="isDetailPreview || isFormDisabled || isFormStatus"
                    class="box-preview"
                    v-html="ticket.ticketDetail">
                  </div>
                  <wysiwyg
                    v-else
                    v-model="ticket.ticketDetail"
                  />
                  <!-- <quill-editor
                    v-model="ticket.ticketDetail"
                    :rules="{ required: false }"
                    name="Incident Detail"
                    label="Incident Detail"
                    placeholder="Input Incident Detail"
                    trim /> -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Incident Resolve">
                  <div
                    v-if="!isFormDisabled && !isFormStatus"
                    class="my-2">
                    <PreviewSwitch
                      v-model="isResolvePreview"
                    >
                      Preview
                    </PreviewSwitch>
                  </div>
                  <div
                    v-if="isResolvePreview || isFormDisabled || isFormStatus"
                    class="box-preview"
                    v-html="ticket.ticketResolve">
                  </div>
                  <wysiwyg
                    v-else
                    v-model="ticket.ticketResolve"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="ticket.remark1"
                  :disabled="isFormDisabled || isFormStatus"
                  :rules="{ required: false }"
                  name="Remark"
                  label="Remark" />
              </b-col>
            </b-row>
            <b-button
              v-if="!isFormDisabled && !isFormStatus"
              v-ripple.400="'rgba(94,88,115)'"
              style="background: #5E5873 !important"
              class="mt-3"
              type="button"
              @click="openFile()"
            >
              Attach Files
            </b-button>
            <input
              v-if="!isFormDisabled && !isFormStatus"
              ref="addFiles"
              class="d-none"
              type="file"
              multiple
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*"
              @change="onChangeFile($event)"
            >
            <b-col
              class="my-2"
              cols="12">
              <ul>
                <li
                  v-for="(item, index) in files"
                  :key="index"
                  class="my-2"
                >
                  <span
                    class="relative">
                    <label class="remove-file">
                      <span
                        v-if="!isFormDisabled && !isFormStatus"
                        class="pointer"
                        @click="removeFile(index)">
                        X
                      </span>
                    </label>
                    <a
                      :href="item.url"
                      target="_blank">{{ item.name }}</a>
                  </span>
                </li>
              </ul>
            </b-col>
            <div class="d-flex mt-2">
              <b-button
                v-if="!isFormDisabled && !isFormStatus"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mt-5 mr-2 btn-submit"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                v-if="!isFormDisabled && !isFormStatus"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-5 mr-2 btn-submit"
                @click="saveAndClosed()"
              >
                Save and closed
              </b-button>
              <b-button
                v-if="!isFormStatus && !isFormDisabled"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="mt-5 mr-2 btn-submit"
                variant="primary"
                @click="transferTicket()">
                Transfer
              </b-button>
              <b-button
                v-if="!isFormStatus && !isFormDisabled"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="referTicket()">
                Refer
              </b-button>
              <b-button
                v-if="!isFormDisabled && !isFormStatus"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="warning"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="showSendEmailModal()"
              >
                Send to email
              </b-button>
              <b-button
                v-if="!isFormStatus && !isFormDisabled"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="danger"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="voidTicket()">
                Void
              </b-button>
              <b-button
                v-if="includeStatus(ticket.ticketStatus, 'closed')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="duplicateTicket()">
                Duplicate
              </b-button>
              <b-button
                v-if="includeStatus(ticket.ticketStatus, 'closed')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="success"
                type="button"
                class="mt-5 mr-2 btn-submit"
                @click="reOpenTicket()">
                Re Open
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PreviewSwitch from '@/components/PreviewSwitch.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import ConfirmModal2 from '@/components/ConfirmModal.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import AutoCompleteField from '@/components/Form/AutoCompleteField.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import ConfirmModal from '@/components/Modal/ConfirmModal.vue'
import StatusModal from '@/components/Modal/StatusModal.vue'
// import TipTab from '@/components/TipTab.vue'
import KnowledgeModal from '@/views/Tickets/components/KnowledgeModal.vue'
import SendToEmailModal from '@/components/Modal/SendToEmailModal.vue'
import KnowledgeProvider from '@/resources/KnowledgeBaseProvider'
import UserProvider from '@/resources/UserProvider'
import TicketProvider from '@/resources/TicketProvider'
import MicrosoftProvider from '@/resources/MicrosoftProvider'
import TimePicker from '@/components/Form/TimePicker.vue'
import roleEnum from '@/common/role.enum'
import ReOpenConfirmModal from './ReOpenConfirmModal.vue'
import ReferModal from './ReferModal.vue'
import TransferModal from './TransferModal.vue'

const KnowledgeService = new KnowledgeProvider()
const TicketService = new TicketProvider()
const UserService = new UserProvider()
const MicrosoftService = new MicrosoftProvider()

export default {
  components: {
    TimePicker,
    ReOpenConfirmModal,
    TextFieldInput,
    AutoCompleteField,
    SelectField,
    TextAreaInput,
    ConfirmModal,
    StatusModal,
    KnowledgeModal,
    SendToEmailModal,
    ConfirmModal2,
    ReferModal,
    TransferModal,
    PreviewSwitch
    // TipTab
  },
  props: {
    allRef: {
      type: Object,
      default: () => {}
    },
    initialTicket: {
      type: Object,
      default: () => ({})
    },
    initialResolveDate: {
      type: String,
      default: ''
    },
    initialDueDate: {
      type: String,
      default: ''
    },
    tabIndex: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isDetailPreview: false,
      isResolvePreview: false,
      remark: '',
      searchCodeAndSubject: '',
      ticketCodeRefer: null,
      isFullLoaded: false,
      searchKnowledge: '',
      isConfirmModalActive: false,
      isSubmitModalActive: false,
      isKnowledgeModal: false,
      isSendEmailModal: false,
      isRequiredCategory: false,
      userItems: [],
      userName: '',
      priorityOption: [
        { label: 'Low', value: 'low' },
        { label: 'Medium', value: 'medium' },
        { label: 'High', value: 'high' }
      ],
      isRemark1: true,
      isRemark2: false,
      isRemark3: false,
      addItem: {},
      lvlOptions: {
        lvlOneOptions: [],
        lvlTwoOptions: [],
        lvlThreeOptions: [],
        lvlFourOptions: [],
        lvlFiveOptions: [],
        lvlSixOptions: []
      },
      currentParentId: null,
      knowledgeItems: [],
      files: [],
      knowledgePagination: {
        page: 1,
        totalDocs: 1,
        limit: 10
      },
      confirmModal: {
        title: 'Are you sure ?',
        description: 'You want to Void ?'
      },
      customerProfileAndContact: []
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user',
      ticketsStore: 'ticket/tickets'
    }),
    isFormDisabled () {
      const user = this.$store.getters['User/user']
      const { ticket } = this
      if (!user || !ticket) {
        return true
      }

      if (user?.id === ticket?.progressBy?.id) {
        return false
      }

      if (user?.role === roleEnum.AGENT || user?.role === roleEnum.SUPERVISOR) {
        if (user?.userTeamId === ticket?.progressBy?.userTeamId) {
          return false
        }
      }

      if (user?.role === roleEnum.SUPER_ADMIN || user?.role === roleEnum.MANAGEMENT || user?.role === roleEnum.MANAGER) {
        if (user?.userTeamId === ticket?.progressBy?.userTeamId || user?.userGroupId === ticket?.progressBy?.userGroupId) {
          return false
        }
      }

      return true
    },
    isFormStatus () {
      return this.includeStatus(this.ticket?.ticketStatus, 'closed', 'void')
    },
    categories: {
      get () {
        return this.allRef.categories
      }
    },
    ticket: {
      get () {
        return this.initialTicket
      },

      set () {
        this.$emit('update:ticket-value')
      }
    },
    resolveDate: {
      get () {
        return this.initialResolveDate
      },

      set () {
        this.$emit('update:resolve-date-value')
      }
    },
    dueDate: {
      get () {
        return this.initialDueDate
      },

      set () {
        this.$emit('update:due-date-value')
      }
    },
    subjectModifier: {
      get () {
        return `${this?.ticket?.subject || ''}`
      },

      set (val) {
        this.$emit('update:subject', val)
      }
    },
    messageModifier () {
      return `
        ${this.ticket.ticketDetail ? `<p><strong>Incident Detail</strong></p> ${this.ticket.ticketDetail}` : ''}
      `
    }
  },
  async mounted () {
    try {
      this.isFullLoaded = false
      this.$store.dispatch('activity/increaseLoading')
      this.filterParentCategory()
      this.ticketCodeRefer = this.ticket.ticketCodeRefer
      this.setOptionLvlOne(this.categories)
      this.userName = `${this.ticket?.progressBy?.firstName} ${this.ticket?.progressBy?.lastName}`
      if (this.ticket && this.ticket?.attachFiles && typeof this.ticket?.attachFiles === 'string') {
        this.files = JSON.parse(this.ticket.attachFiles)
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.ticketCodeRefer = this.ticket.ticketCodeRefer
      this.isFullLoaded = true
      this.$store.dispatch('activity/decreaseLoading')
    }
  },
  methods: {
    ...mapActions({
      deleteTicket: 'ticket/DELETE_TICKET'
      // overwriteTicket: 'ticket/OVERWRITE_TICKET'
    }),
    /**
     *
     * @param {Object} payload
     * @param {string} payload.subject
     * @param {string} payload.message
     * @param {string} payload.to
     * @param {string} payload.cc
     */
    async sendEmail (payload) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        await MicrosoftService.sendMail({
          subject: `Ticket No. [${this.ticket?.code}] ${payload?.subject}`,
          html: payload?.message,
          to: payload?.to,
          cc: payload?.cc
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: 'Email Send Successfully'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            icon: 'EditIcon',
            variant: 'danger',
            text: 'Email Send Failed'
          }
        })
      } finally {
        this.isFullLoaded = false
        this.isSendEmailModal = false
        this.$nextTick(() => {
          this.isFullLoaded = true
        })
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getTicketById () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        await TicketService.findOne()
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getAllKnowledgeByCategory () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const categoryId = this.getLastItem()
        if (categoryId) {
          const data = await KnowledgeService.paginate(this.knowledgePagination.page, this.knowledgePagination.limit, {
            categoriesIds: [categoryId]
          })
          this.setPaginationKnowledge(data.page, data.totalDocs)
          this.knowledgeItems = data.data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    // async getKnowledgeByCategory () {
    //   try {
    //     const categoryId = this.getLastItem()
    //     const { data } = await KnowledgeService.findAllByCategory(categoryId)
    //     if (data && Array.isArray(data) && data.length > 0) {
    //       const knowledge = data[0]
    //       this.ticket.ticketDetail = knowledge.problemDetail
    //       this.ticket.ticketResolve = knowledge.solutionDetail
    //     } else {
    //       this.setInitialKnowledge()
    //     }
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    async searchUserByName () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        setTimeout(async () => {
          const { data } = await UserService.paginate(1, 10, { searchFullName: this.userName })
          const result = []
          data.forEach((item) => {
            result.push({ ...item, fullName: `${item.firstName} ${item.lastName}` })
          })
          this.userItems = result
        }, 100)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async voidTicket () {
      try {
        const confirm = await this.$refs.confirmModal.show({ description: 'You want to void ticket ?' })
        if (confirm) {
          this.$store.dispatch('activity/increaseLoading')
          await TicketService.voidTicket(this.ticket.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Void Successfully',
              icon: 'EditIcon',
              variant: 'success',
              text: '👋 Ticket has been void'
            }
          })
          this.deleteTicket(this.tabIndex)
          this.$router.push('/ticket')
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Void has been failed'
          }
        })
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async duplicateTicket () {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const payload = {
          ...this.ticket
        }
        payload.ticketStatus = 'open'
        // delete payload.code
        delete payload.id
        delete payload.createdAt
        delete payload.createdBy
        delete payload.updatedAt
        delete payload.updatedBy
        delete payload.status
        delete payload.resolveDate
        delete payload.dueDate
        delete payload.closedDate
        payload.contactPhoneNo = this.$replacePhoneNumber(payload.contactPhoneNo, '+66')
        const { data } = await TicketService.duplicateTicket(payload)
        this.ticket = data
        this.$router.replace(`/ticket/detail/${data.id}`)
        this.$router.go(0)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async reOpenTicket () {
      try {
        const confirm = await this.$refs.reOpenConfirmModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
        if (confirm) {
          this.$store.dispatch('activity/increaseLoading')
          const payload = {
            remark: this.remark
          }
          await TicketService.reOpenTicket(this.ticket.id, payload)
          this.$router.go()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async referTicket () {
      try {
        const confirm = await this.$refs.referModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
        if (confirm) {
          this.$store.dispatch('activity/increaseLoading')
          const { data } = await TicketService.findByCode(this.ticketCodeRefer)
          if (!data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Can not find Ticket ${this.ticketCodeRefer}`,
                icon: 'EditIcon',
                variant: 'danger',
                text: '👋 Refer Ticket has been failed'
              }
            })
            this.ticketCodeRefer = this.ticket.ticketCodeRefer
            return true
          }
          this.ticket.ticketCodeRefer = this.ticketCodeRefer
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
      return true
    },
    async transferTicket () {
      try {
        const agentId = await this.$refs.transferModal.show({ description: 'You want to Update ticket ?', variant: 'success' })
        if (agentId && this.ticket.id) {
          this.$store.dispatch('activity/increaseLoading')
          await TicketService.transferTicket(this.ticket.id, agentId)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully',
              icon: 'EditIcon',
              variant: 'success',
              text: 'Transfer Successfully'
            }
          })

          setTimeout(() => {
            const tickets = this.ticketsStore
            if (Array.isArray(tickets)) {
              const findTicketIndex = tickets?.findIndex((item) => item?.id === this.ticket?.id)
              if (findTicketIndex >= 0) {
                this.deleteTicket(findTicketIndex)
              }
            }
            this.$router.go(0)
          }, 500)
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            icon: 'EditIcon',
            variant: 'danger',
            text: 'Transfer Failed'
          }
        })
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async saveAndClosed () {
      const validate = await this.$refs.TicketAddForm.validate()
      if (validate) {
        const payload = {
          ...this.ticket,
          files: this.files
        }
        payload.contactPhoneNo = this.$replacePhoneNumber(payload.contactPhoneNo, '+66')
        this.$emit('save-and-closed', payload)
      }
    },
    includeStatus (status, ...target) {
      return target.includes(status)
    },
    selectUser (val) {
      const payload = {
        id: val.id,
        username: val.username,
        firstName: val.firstName,
        lastName: val.lastName
      }
      this.$emit('selectd-user', payload)
    },
    filterUser (items, value) {
      const upperValue = value.toUpperCase()
      return items.filter((str) => (str && str.fullName ? str.fullName.toUpperCase().indexOf(upperValue) >= 0 : ''))
    },
    showSendEmailModal () {
      this.isSendEmailModal = !this.isSendEmailModal
    },
    selectdKnowledge (val) {
      this.ticket.ticketDetail = val?.problemDetail
      this.ticket.ticketResolve = val?.solutionDetail
      this.isKnowledgeModal = false
    },
    checkRequireCategory (category) {
      if (Array.isArray(category) && category.length > 0) {
        return true
      }
      return false
    },
    onSubmit () {
      const payload = {
        ...this.ticket,
        files: this.files

      }
      this.$emit('add-ticket', payload)
    },
    onDiscard () {
      this.resetForm()
      this.$router.push('/ticket')
    },
    resetForm () {
      this.$refs.TicketAddForm.reset()
    },
    openFile () {
      this.$refs.addFiles.click()
    },
    onChangeFile (event) {
      const { files } = event.target
      for (let i = 0; i < files.length; i++) {
        this.files.push({
          file: files[i],
          name: files[i].name,
          url: URL.createObjectURL(files[i])
        })
      }
    },
    removeFile (index) {
      this.files.splice(index, 1)
    },
    openAddModal (val) {
      this.addItem = {
        'Ticket Type :': val.incidentType.label,
        'Customer Name :': val.customerName,
        'Contact Name :': val.contactName,
        'Ticket No. :': val.incidentNo
      }
      this.isConfirmModalActive = true
    },
    confirmAdd () {
      this.isSubmitModalActive = true
    },
    selectedItem (parentId, level) {
      const filter = this.findCategory(parentId)
      switch (level) {
      case 1:
        this.lvlOptions.lvlTwoOptions = filter
        this.lvlOptions.lvlThreeOptions = []
        this.lvlOptions.lvlFourOptions = []
        this.lvlOptions.lvlFiveOptions = []
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv2 = null
        this.ticket.categoryLv3 = null
        this.ticket.categoryLv4 = null
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 2:
        this.lvlOptions.lvlThreeOptions = filter
        this.lvlOptions.lvlFourOptions = []
        this.lvlOptions.lvlFiveOptions = []
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv3 = null
        this.ticket.categoryLv4 = null
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 3:
        this.lvlOptions.lvlFourOptions = filter
        this.lvlOptions.lvlFiveOptions = []
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv4 = null
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 4:
        this.lvlOptions.lvlFiveOptions = filter
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv5 = null
        this.ticket.categoryLv6 = null
        break
      case 5:
        this.lvlOptions.lvlSixOptions = filter
        this.lvlOptions.lvlSixOptions = []
        this.ticket.categoryLv6 = null
        break
      default:
        break
      }
      const result = this.getLastItem()
      if (this.currentParentId !== result) {
        this.currentParentId = result
        this.$emit('selected-category', result)
      }
      // this.getKnowledgeByCategory()
    },
    setOptionLvlOne (val) {
      if (val) {
        this.lvlOptions.lvlOneOptions = val.filter((item) => item?.level === 1 && item?.visibelGroup?.includes(this.profile?.userGroupId))
      }
    },
    findCategory (parentId) {
      return this.allRef.categories.filter((item) => item.parentId === parentId)
    },
    filterParentCategory () {
      const lastParintId = this.getLastItem()
      const filter = this.allRef.categories.filter((item) => Number(item?.id) === Number(lastParintId))
      const result = [...filter]
      for (let i = 0; i < filter[0]?.level; i++) {
        const fil = this.allRef.categories.filter((item) => item.id === result[i]?.parentId)
        result.push(...fil)
        const filters = this.findCategory(result[i]?.parentId)
        switch (result[i]?.level) {
        case 1:
          this.lvlOptions.lvlOneOptions = filters
          break
        case 2:
          this.lvlOptions.lvlTwoOptions = filters
          break
        case 3:
          this.lvlOptions.lvlThreeOptions = filters
          break
        case 4:
          this.lvlOptions.lvlFourOptions = filters
          break
        case 5:
          this.lvlOptions.lvlFiveOptions = filters
          break
        case 6:
          this.lvlOptions.lvlSixOptions = filters
          break
        default:
          break
        }
      }
      result.forEach((item) => {
        switch (item?.level) {
        case 1:
          this.ticket.categoryLv1 = item.id
          break
        case 2:
          this.ticket.categoryLv2 = item.id
          break
        case 3:
          this.ticket.categoryLv3 = item.id
          break
        case 4:
          this.ticket.categoryLv4 = item.id
          break
        case 5:
          this.ticket.categoryLv5 = item.id
          break
        case 6:
          this.ticket.categoryLv6 = item.id
          break
        default:
          break
        }
      })
    },
    getLastItem () {
      const categoriesLevel = {
        categoryLv1: this.ticket.categoryLv1,
        categoryLv2: this.ticket.categoryLv2,
        categoryLv3: this.ticket.categoryLv3,
        categoryLv4: this.ticket.categoryLv4,
        categoryLv5: this.ticket.categoryLv5,
        categoryLv6: this.ticket.categoryLv6
      }
      const convertToArray = Object.keys(categoriesLevel).map((key) => categoriesLevel[key])
      let result = null
      convertToArray.forEach((item) => {
        if (item && item !== '') {
          result = item
        }
      })
      return result
    },
    async showModal () {
      await this.getAllKnowledgeByCategory()
      this.isKnowledgeModal = true
    },
    showCurrentPageKnowledge (page) {
      this.setPaginationKnowledge(page)
      this.getAllKnowledgeByCategory()
    },
    setPaginationKnowledge (page, totalDocs) {
      this.knowledgePagination.page = page || this.knowledgePagination.page
      this.knowledgePagination.totalDocs = totalDocs || this.knowledgePagination.totalDocs
    },
    fetchSearchKnowledge () {
      this.setPaginationKnowledge(1)
      this.getAllKnowledgeByCategory()
    },
    chooseTicketType (e) {
      if (this.allRef && this.allRef.ticketType.length > 0) {
        const currentTicketType = this.allRef.ticketType.filter((item) => item.id === e)
        if (currentTicketType && currentTicketType.length > 0) {
          if (currentTicketType[0]?.isRequiredCategory) {
            this.isRequiredCategory = true
          } else {
            this.isRequiredCategory = false
          }
        } else {
          this.isRequiredCategory = false
        }
      }
    },
    setInitialKnowledge () {
      this.ticket.ticketDetail = ''
      this.ticket.ticketResolve = ''
    }
  }
}
</script>

<style scoped>
::v-deep .ql-container.ql-snow>.ql-editor {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}
.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.remove-file {
  position: absolute;
  right: -17px;
  bottom: 11px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
